<template>
	<div
	class="tw-flex tw-gap-[10px] tw-p-[10px] tw-justify-between tw-cursor-pointer"
	project-card
	@click="$router.push({ name: 'project', params: { id: project.id } })"
	>
		<div class="tw-flex tw-flex-col tw-gap-[10px] tw-overflow-hidden">
			<h2 class="majFL tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden">
				{{ project.name }}
			</h2>

			<div>
				{{ sentence }}
			</div>
		</div>
		
		<v-menu
		bottom
		right
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
				v-if="$hasRight('workspaces.editWorkspaceBtn')"
				icon
				v-bind="attrs"
				v-on="on"
				>
					<v-icon class="m_brown">
						mdi-dots-horizontal
					</v-icon>
				</v-btn>
			</template>

			<v-list
			class="pa-0"
			width="15vh"
			>
				<v-list-item @click="dialog = true">
					<v-list-item-title>Éditer</v-list-item-title>
				</v-list-item>

				<v-list-item
				@click="archiveProject({ isArchived: true })"
				v-if="!project.isArchived"
				>
					<v-list-item-title>Archiver</v-list-item-title>
				</v-list-item>

				<v-list-item
				@click="archiveProject({ isArchived: false })"
				v-if="project.isArchived"
				>
					<v-list-item-title>Désarchiver</v-list-item-title>
				</v-list-item>

				<v-list-item
				@click="deleteDialog = true" 
				v-if="$hasRight('globalActions.deleteCampaignsAndProjects')"
				>
					<v-list-item-title class="red-text">
						Supprimer
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<ProjectCreationModale
		v-if="dialog"
		@closeModale="dialog = false"
		:isItEditModal="true"
		:project="project"
		@update:project="updateProject"
		/>

		<DeleteCampaignProjetPopup
		v-if="deleteDialog"
		@closeModale="deleteWorkspace()"
		:campaignOrProjectId="project.id"
		type="workspace"
		/>
	</div>
</template>

<script>
import ProjectCreationModale from "./CreateWorkspace.modal.vue";
import DeleteCampaignProjetPopup from "../popup/DeleteCampaignProjet.popup.vue";
import {mapActions} from "vuex";

export default {
	components: {
		ProjectCreationModale,
		DeleteCampaignProjetPopup
	},
	props: {
		project: {

		}
	},
	data(){
		return {
			dialog: false,
			deleteDialog: false
		};
	},
	computed: {
		sentence(){
			const globalCount = this.project.campaignsCount;
			if(0 === globalCount.total || 0 === globalCount.length){
				return "Aucune campagne";
			}
			let sentence = "";

			if(0 < globalCount.auscultation){
				sentence +=
				globalCount.auscultation +
				" campagne" +
				(globalCount.auscultation > 1 ? "s" : "") +
				" d'auscultation";
			}

			if(0 < globalCount.asbestosPAHTH){
				if(0 < globalCount.auscultation) sentence += " / ";
				sentence +=
				globalCount.asbestosPAHTH +
				" campagne" +
				(globalCount.asbestosPAHTH > 1 ? "s" : "") +
				" Amiante/HAP/HCT";
			}

			if(0 < globalCount.structural){
				if(0 < globalCount.asbestosPAHTH) sentence += " / ";
				sentence +=
				globalCount.structural +
				" campagne" +
				(globalCount.structural > 1 ? "s" : "") +
				" structurelle" +
				(globalCount.structural > 1 ? "s" : "");
			}
			return sentence;
		}
	},
	methods: {
		...mapActions("workspace", ["updateWorkspaces", "updateWorkspace"]),
		updateProject(data){
			this.dialog = false;
			this.updateWorkspace(data);
		},
		archiveProject(archived){
			const action = archived.isArchived === true ? "archiver" : "désarchiver";
			this.$toast(
				"info",
				"Êtes vous sûr de vouloir " + action + " le projet ?",
				action,
				async() => {
					const workspace = await this.$api.workspaces.editArchived(
						this.project.id,
						archived.isArchived
					);

					await this.updateWorkspaces(this.$route.params.archived === "archived");
				}
			);
		},
		deleteWorkspace(){
			this.deleteDialog = false;
		}
	}
};
</script>

<style lang="scss">
div[project-card]{
	user-select: none;
    border-radius: 4px;
    border: solid rgba(0,0,0,0.12) 1px;
}
.red-text {
	color: red
}
</style>

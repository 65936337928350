<template>
	<section
	class="ProjectCreationModale"
	@click="$event.stopPropagation()"
	>
		<v-card
		flat
		outlined
		class="ProjectCreationModale__card"
		>
			<v-card-title class="pb-2 pl-7 pt-0">
				<span class="text-h5 pb-0">{{
					isItEditModal
						? "Modifier les informations du projet"
						: "Créer un projet"
				}}</span>
			</v-card-title>

			<v-form
			ref="form"
			@submit.prevent="isItEditModal ? modifyProject() : createProject()"
			class="form"
			>
				<v-card-text class="pb-0">
					<v-container>
						<v-row>
							<v-col
							cols="12"
							class="pb-1 pt-0"
							>
								<v-text-field
								label="Nom du projet*"
								dense
								autofocus
								tabindex="1"
								required
								outlined
								clearable
								v-model="workspaceForm.name"
								:rules="[$rules.required, $rules.min5Chars]"
								ref="name"
								@focus="$refs.name.resetValidation()"
								@blur="
									workspaceForm.name === '' || workspaceForm.name === null
										? $refs.name.resetValidation()
										: ''
								"
								:validate-on-blur="true"
								/>
							</v-col>
						</v-row>

						<v-row class="mt-0">
							<v-col
							cols="12"
							class="pb-0"
							>
								<div class="inputBtn">
									<v-autocomplete
									label="Organisation client*"
									dense
									tabindex="3"
									required
									outlined
									clearable
									v-model="workspaceForm.customerOrganizationId"
									:rules="[$rules.required]"
									:items="customerOrganizations"
									:loading="customerOrganizations.length === 0"
									item-text="name"
									item-value="id"
									@change="
										workspaceForm.customerOrganizationId !== null
											? getCustomerSitesByOrganization()
											: (workspaceForm.customerSiteId = null)
									"
									ref="customerOrganizationId"
									@focus="$refs.customerOrganizationId.resetValidation()"
									:validate-on-blur="true"
									/>

									<medium-button-slot
									v-if="$hasRight('organizations.create')"
									@click="openCreateOrgModale(false)"
									>
										<v-icon>mdi-plus</v-icon>
									</medium-button-slot>
								</div>

								<div class="inputBtn">
									<v-autocomplete
									:disabled="null === workspaceForm.customerOrganizationId"
									label="Site client*"
									dense
									tabindex="4"
									required
									outlined
									clearable
									v-model="workspaceForm.customerSiteId"
									:rules="[$rules.required]"
									:items="customerSites"
									:loading="
										customerSites.length === 0 &&
											null !== workspaceForm.customerOrganizationId &&
											loadingInternalSites
									"
									item-text="name"
									item-value="id"
									:validate-on-blur="true"
									/>

									<medium-button-slot
									:_disabled="null === workspaceForm.customerOrganizationId"
									v-if="$hasRight('sites.create')"
									@click="openSiteCreationModal(false)"
									>
										<v-icon>mdi-plus</v-icon>
									</medium-button-slot>
								</div>

								<div
								class="inputBtn"
								v-if="$hasRight('globalActions.superAdmin')"
								>
									<v-autocomplete
									label="Organisation bureau d'étude*"
									dense
									tabindex="5"
									required
									outlined
									clearable
									v-model="workspaceForm.organizationId"
									:rules="[$rules.required]"
									:items="internalOrganizations"
									:loading="internalOrganizations.length === 0"
									item-text="name"
									item-value="id"
									@change="
										workspaceForm.organizationId !== null
											? getInternalSitesByOrganization()
											: (workspaceForm.siteId = null);
										if (
											isItEditModal === true &&
											workspaceForm.organizationId !== null &&
											workspaceForm.organizationId !==
											project.site.organization.id
										)
											showWarning = true;
									"
									ref="organizationId"
									@focus="$refs.organizationId.resetValidation()"
									:validate-on-blur="true"
									/>

									<medium-button-slot
									v-if="$hasRight('organizations.create')"
									@click="openCreateOrgModale(true)"
									>
										<v-icon>mdi-plus</v-icon>
									</medium-button-slot>
								</div>

								<div
								v-else
								v-show="isItEditModal ? true : isOrganisationLoaded"
								>
									<p>
										{{
											isItEditModal
												? "Organisation du projet : " +
													project.site.organization.name
												: "Votre organisation : " + userOrganization.name
										}}
									</p>
								</div>

								<div class="inputBtn">
									<v-autocomplete
									:disabled="null === workspaceForm.organizationId"
									label="Site bureau d'étude*"
									dense
									tabindex="6"
									required
									outlined
									clearable
									v-model="workspaceForm.siteId"
									:rules="[$rules.required]"
									:items="internalSites"
									:loading="
										this.internalSites.length === 0 &&
											null !== workspaceForm.organizationId &&
											loadingCustomerSites
									"
									item-text="name"
									item-value="id"
									:validate-on-blur="true"
									/>

									<medium-button-slot
									:_disabled="null === workspaceForm.organizationId"
									v-if="$hasRight('sites.create')"
									@click="openSiteCreationModal(true)"
									>
										<v-icon>mdi-plus</v-icon>
									</medium-button-slot>
								</div>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions class="pt-0 pb-0">
					<MediumButtonSlot
					@click="closeModale()"
					class="ml-5"
					>
						Fermer
					</MediumButtonSlot>

					<v-spacer/>

					<HighButtonSlot
					type="submit"
					class="mr-5"
					>
						{{
							isItEditModal ? "Modifier" : "Créer"
						}}
					</HighButtonSlot>
				</v-card-actions>
			</v-form>
		</v-card>

		<CreateOrganizationModale
		v-if="isCreateOrganizationModaleOpened"
		@organizationCreated="handleNewOrg"
		@closePopin="closeCreateOrgModale"
		/>

		<SiteCreateModale
		v-if="isSiteCreateModaleOpen"
		@siteCreated="handleNewSite"
		:isSiteEdit="false"
		:organization="sentOrganisation"
		@closePopin="isSiteCreateModaleOpen = false"
		:siteToEdit="null"
		/>

		<v-dialog
		v-model="showWarning"
		max-width="600"
		>
			<template>
				<v-card>
					<v-toolbar
					flat
					class="d-flex flex justify-center"
					>
						Attention
					</v-toolbar>

					<v-card-text>
						<div class="pr-6 pl-6">
							Le changement d'organisation affecte l'attribution de l'équipe
							projet, veuillez renseigner la nouvelle équipe dans chacune des
							campagnes du projet
						</div>
					</v-card-text>

					<v-card-actions class="justify-center">
						<ButtonSlot
						_theme="light-gray"
						@click="showWarning = false"
						>
							Ok
						</ButtonSlot>
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>
	</section>
</template>

<script>
import CreateOrganizationModale from "@/components/entities/organization/CreateOrganization.modal";
import SiteCreateModale from "../entities/site/CreateSite.modal.vue";

export default {
	name: "ProjectCreateModale",
	components: {
		SiteCreateModale,
		CreateOrganizationModale
	},
	props: {
		isItEditModal: {
			type: Boolean,
			required: true
		},
		project: {
			type: Object,
			required: false
		}
	},
	data(){
		return {
			isNewOrgInternal: false,
			isNewSiteInternal: false,
			customerSites: [],
			internalSites: [],
			organizations: [],
			userOrganization: {
				id: "",
				name: ""
			},
			sentOrganisation: {},
			isCreateOrganizationModaleOpened: false,
			isSiteCreateModaleOpen: false,
			workspaceForm: {
				name: "",
				description: null,
				customerOrganizationId: null,
				customerSiteId: null,
				organizationId: null,
				siteId: null
			},
			rules: {
				required: value => !!value || "Requis",
				len: value => value.length >= 5 || "Minimum 5 caractères"
			},
			userSite: {
				id: ""
			},
			loadingInternalSites: true,
			loadingCustomerSites: true,
			isOrganisationLoaded: false,
			showWarning: false
		};
	},
	computed: {
		customerOrganizations(){
			let customerOrganisations = this.organizations.filter(org => {
				return false === org.isInternal;
			});
			if(false === this.$hasRight("globalActions.superAdmin")){
				customerOrganisations.filter(org => {
					return (
						org.parentOrganization.id === this.workspaceForm.organizationId
					);
				});
			}
			return customerOrganisations;
		},
		internalOrganizations(){
			return this.organizations.filter(org => {
				return true === org.isInternal;
			});
		}
	},
	watch: {
		"workspaceForm.siteId": {
			handler: function(newId, before){
				if(this.userSite.id !== newId && before === this.userSite.id) this.$toast(
					"warning",
					"Vous êtes sur le point d'attribuer un site différent du vôtre"
				);
			},
			deep: true
		}
	},
	methods: {
		async modifyProject(){
			const project = await this.$api.workspaces.edit(
				this.project.id,
				this.workspaceForm
			);
			this.$emit("update:project", project);
		},
		openCreateOrgModale(internal){
			this.isNewOrgInternal = internal;
			this.isCreateOrganizationModaleOpened = true;
		},
		closeCreateOrgModale(){
			this.isCreateOrganizationModaleOpened = false;
		},
		handleNewOrg(org){
			this.organizations.push(org);
			if(this.isNewOrgInternal) this.workspaceForm.organizationId = org.id;
			else this.workspaceForm.customerOrganizationId = org.id;
		},
		handleNewSite(site){
			if(this.isNewSiteInternal){
				this.internalSites.push(site);
				this.workspaceForm.siteId = site.id;
			}
			else {
				this.customerSites.push(site);
				this.workspaceForm.customerSiteId = site.id;
			}
		},
		async getInternalSitesByOrganization(){
			this.loadingCustomerSites = true;
			this.internalSites = await this.$api.organizations.getAllSitesFromOrganization(
				this.workspaceForm.organizationId
			);
			this.loadingCustomerSites = false;
		},
		async getCustomerSitesByOrganization(){
			this.loadingInternalSites = true;
			this.customerSites = await this.$api.organizations.getAllSitesFromOrganization(
				this.workspaceForm.customerOrganizationId
			);
			this.loadingInternalSites = false;
		},
		getUserOrganization(){
			const userId = this.$store.state.user.current.userId;
			if(undefined === userId) return setTimeout(this.getUserOrganization, 500);
			this.userOrganization.id = this.$store.state.user.current.organizationId;
			this.userOrganization.name =
        this.$store.state.user.current.organizationName;

			this.workspaceForm.organizationId = this.project.site.organization.id;
			this.getInternalSitesByOrganization();
		},
		async getOrganizations(){
			this.organizations = await this.$api.organizations.findAll();
		},
		refreshViewData(){
			this.getUserSite();
		},
		openSiteCreationModal(internal){
			this.isNewSiteInternal = internal;
			internal
				? (this.sentOrganisation = this.organizations.find(
					e => e.id === this.workspaceForm.organizationId
				))
				: (this.sentOrganisation = this.organizations.find(
					e => e.id === this.workspaceForm.customerOrganizationId
				));
			this.isSiteCreateModaleOpen = true;
		},
		async getUserSite(){
			const user = await this.$api.users.findById(
				this.$store.state.user.current.userId
			);
			this.userSite = user.sites[0];
			if(false === this.isItEditModal) this.workspaceForm.siteId = user.sites[0].id;
		},
		createProject(){
			if(!this.$refs.form.validate()){
				return;
			}
			this.$emit("createProject", this.workspaceForm);
		},
		closeModale(){
			this.$emit("closeModale");
		},
		refreshModale(){
			this.getUserSite();
			this.getUserOrganization();
		},
		fullfillEditModalInput(){
			// si on a cliqué sur le bouton d'édition de projet
			if(true === this.isItEditModal){
				// on pré-rempli les valeurs des inputs du form avec les informations du projet édité
				this.workspaceForm.name = this.project.name;
				this.workspaceForm.customerOrganizationId =
          this.project.customerSite.organization.id;
				this.getCustomerSitesByOrganization();
				this.workspaceForm.customerSiteId = this.project.customerSite.id;
				this.workspaceForm.organizationId = this.project.site.organization.id;
				this.workspaceForm.siteId = this.project.site.id;
			}
		}
	},
	mounted(){
		this.$root.$on("closeCurrent", this.closeModale);
		this.getOrganizations();
		this.getUserSite();
		this.getUserOrganization();
		this.fullfillEditModalInput();
	}
};
</script>

<style lang="scss">
@import "@/assets/scss/_extends.scss";

.ProjectCreationModale {
  @extend %popinView;

  &__card {
    max-width: 600px;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }
  .v-select .v-input__append-inner {
    align-self: flex-start !important;
  }
  .inputBtn {
    display: flex;

    .MediumButtonSlot {
      margin-left: 20px;
      height: 40px;
      min-width: 40px;
      padding: 0;
    }
  }
}
</style>

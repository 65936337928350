<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		:title="$route.params.archived? 'Liste des projets archivés' : 'Liste des projets en cours'"
		isBackButtonHidden
		>
			<MediumButtonSlot
			v-if="$hasRight('workspaces.createWorkspaceBtn') && !$route.params.archived"
			@click="dialog = true"
			>
				Créer un nouveau projet
			</MediumButtonSlot>
		</HeaderSlot>

		<div class="tw-flex tw-gap-[10px] tw-h-[50px]">
			<v-text-field
			class="tw-max-w-[275px]"
			append-icon="mdi-magnify"
			clearable
			hide-details
			:label="$t('search_project')"
			v-model="fn"
			/>

			<v-text-field
			class="tw-max-w-[275px]"
			append-icon="mdi-magnify"
			clearable
			hide-details
			:label="$t('search_campaign')"
			v-model="fc"
			/>

			<v-select
			class="tw-max-w-[275px]"
			clearable
			hide-details
			:label="$t('filter_type')"
			:items="campaignTypes"
			item-value="value"
			item-text="name"
			v-model="ft"
			/>

			<v-select
			v-if="$hasRight('globalActions.showOrganizationsSelect')"
			class="tw-max-w-[275px]"
			:items="customerOrganizations"
			v-model="fo"
			:label="$t('filter_organization')"
			clearable
			hide-details
			/>

			<v-select
			v-if="
				$hasRight('globalActions.showSitesSelect') ||
					(
						!$hasRight('globalActions.showOrganizationsSelect') &&
						$hasRight('globalActions.showSitesSelect')
					)
			"
			class="tw-max-w-[275px]"
			:items="site"
			v-model="fs"
			:label="$t('filter_site')"
			clearable
			hide-details
			/>
		</div>

		<div class="tw-flex tw-grow tw-overflow-y-auto tw-flex-col tw-gap-[10px] tw-pr-[5px]">
			<div
			v-if="showMacroProjet && $store.state.user.current.job === null"
			project-card
			class="tw-flex tw-flex-col tw-gap-[10px] tw-p-[10px] tw-justify-between tw-cursor-pointer tw-items-center tw-border-[#2C0703]"
			@click="$router.push('/coring-macro-campaigns')"
			>
				<h2 class="majFL tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden">
					{{ $store.state.user.current.job !== null ? 'Résultats clients' : 'Résultats réseau' }}
				</h2>

				<div>
					{{ $store.state.user.current.job !== null ? 'Visualisez tous les résultats amiante/HAP/HCT de vos clients' : 'Visualisez toutes les informations de votre réseau' }}
				</div>
			</div>

			<ProjectCard
			v-for="project of filterProjects"
			:project="project"
			/>
		</div>

		<ProjectCreationModale
		v-if="dialog"
		@createProject="createProject"
		@closeModale="closeModale"
		:isItEditModal="false"
		/>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ProjectCard from "../components/workspaces/Workspace.card.vue";
import ProjectCreationModale from "../components/workspaces/CreateWorkspace.modal.vue";

export default {
	components: {
		ProjectCard,
		ProjectCreationModale
	},
	data(){
		return {
			dialog: false,
			macroProjet: false,
			campaignTypes: [
				{name: "Auscultation", value: "auscultation"},
				{name: "Carottage", value: "coring"}
			]
		};
	},
	computed: {
		...mapGetters("workspace", [
			"filterProjects", "customerOrganizations", "site", "filterName", "filterOrga", "filterSite", "filterCampaign", "filterType"
		]),
		...mapGetters("user", ["user"]),

		fn: {
			get(){
				return this.filterName;
			},

			set(value){
				this.SET_FILTERNAME(value);
			}
		},
		fo: {
			get(){
				return this.filterOrga;
			},

			set(value){
				this.SET_FILTERORGA(value);
			}
		},
		fs: {
			get(){
				return this.filterSite;
			},

			set(value){
				this.SET_FILTERSITE(value);
			}
		},
		fc: {
			get(){
				return this.filterCampaign;
			},

			set(value){
				this.SET_FILTERCAMPAIGN(value);
			}
		},
		ft: {
			get(){
				return this.filterType;
			},

			set(value){
				this.SET_FILTERTYPE(value);
			}
		},

		showMacroProjet(){
			return !this.filterName &&
				!this.filterOrga &&
				!this.filterSite &&
				this.macroProjet !== false && 
				this.macroProjet.length !== 0;
		}
	},
	watch: {
		$route: {
			handler(){
				this.purgeStore();
				this.initStore(this.$route.params.archived === "archived");
			},
			deep: true
		},
	},
	methods: {
		...mapActions("workspace", [
			"initStore", "purgeStore", "updateWorkspaces"
		]),
		...mapActions("user", ["waitLoadUser"]),
		...mapMutations("workspace", [
			"SET_FILTERNAME", "SET_FILTERORGA", "SET_FILTERSITE", "SET_FILTERCAMPAIGN", "SET_FILTERTYPE"
		]),
		closeModale(){
			this.dialog = false;
		},
		createProject(workspaceForm){
			this.$api.workspaces.create(workspaceForm).then(workspace => {
				this.dialog = false;
				this.updateWorkspaces(this.$route.params.archived === "archived");
			});
		},
	},
	async mounted(){
		this.initStore(this.$route.params.archived === "archived");

		await this.waitLoadUser();
		this.macroProjet = (await this.$axios.get(`/users/${this.user.userId}/macro-projects`)).data;
	},
	destroyed(){
		this.purgeStore();
	}
};
</script>

<style lang="scss">

</style>
